.contentContainer {
  display: grid;
  grid-template-columns: auto;
  gap: 16px;
}

.title {
  color: var(--colour-surface-600, #1b4254);
  font-size: 20px;
  line-height: 26px;
  letter-spacing: 0.1px;
  margin-bottom: 0;
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
  gap: 8px;

  .caretButton {
    width: 32px;
    height: 32px;
    padding: 2px;
  }
}

.drawerContainer {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.commentsButton {
  width: fit-content;
  padding: 5px 8px;

  &--active {
    width: fit-content;
    border: 1px solid var(--colour-surface-200);
    background-color: var(--colour-surface-75);
  }
}

.descriptor {
  display: flex;
  justify-content: start;
  align-items: start;
  flex-direction: row;
  width: 100%;
  gap: 8px;

  .content {
    display: flex;
    justify-content: start;
    align-items: start;
    flex-direction: row;
    width: 100%;

    .task {
      display: flex;
      justify-content: start;
      align-items: start;
      flex-direction: column;
      width: 100%;
      gap: 4px;

      .type {
        font-size: 14px;
        color: var(--colour-text-400);
        margin-bottom: 0px;
        line-height: 14px;
      }

      .titleContainer {
        display: flex;
        width: 100%;
        justify-content: flex-start;
        align-items: center;
        flex-direction: row;
        gap: 8px;

        .title {
          font-size: 20px;
          line-height: 100%;
          vertical-align: middle;
          line-height: 26px;
          font-weight: 600;
          color: var(--colour-text-800);
          margin-bottom: 0px;
        }
      }

      .dueDate {
        font-size: 14px;
        line-height: 100%;
        color: var(--colour-text-600);
        margin-bottom: 0px;
      }
    }
  }
}

.icon {
  width: 40px;
  height: 40px;
  min-width: 40px;
  padding: 2px 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;

  &.open {
    color: var(--colour-info-700);
    background-color: var(--colour-default-light);
  }

  &.overdue {
    color: var(--colour-warning-dark);
    background-color: var(--colour-warning-light);
  }

  &.complete {
    background-color: var(--colour-surface-100);
  }

  &.dismissed {
    background-color: var(--colour-surface-100);
  }
}

.tagList {
  display: flex;
  gap: 8px;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
}

.assignedToUser {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;

  .chip {
    width: 24px;
    height: 24px;
    padding: 4px 4px;
  }
}
